import { render, staticRenderFns } from "./HolidayList.vue?vue&type=template&id=28cd1971"
import script from "../../controllers/holiday/holiday-list.js?vue&type=script&lang=js&external"
export * from "../../controllers/holiday/holiday-list.js?vue&type=script&lang=js&external"
import style0 from "./HolidayList.vue?vue&type=style&index=0&id=28cd1971&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports