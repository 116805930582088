import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';

export default {
    name: "classDetail",
    components: {
        MainLayout,
        Loading
    },

    data() {
        return {
            isLoading: false,
            classInfo: {},
            noDataMsg: CONFIG.NO_DATA_EXIST,
            gradeArr: [],
            subjectAndTeacherArr: {},
            academicYearArr: null,
        };
    },

    methods: {
        getGradeName(campus_id = 1) {
            axios.get('getGradeName?id=' + campus_id, {
                params: {
                    campus_id: store.state.user.campus_id
                }
            }).then(res => {
                let $defaultSelectOption = [{
                    "id": 0,
                    "text": "Select"
                }];
                this.gradeArr = $defaultSelectOption.concat(res.data);
            }).catch((error) => {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
            });
        },

        getClassDetail() {
            var request = {
                params: {
                    campus_id: store.state.user.campus_id
                }
            }
            axios.get("/class/detail/" + this.$route.params.id, request)
                .then(response => {
                    this.classInfo = response.data;
                    this.getAcademicYearByGradeId(this.classInfo.grade_id);
                    this.getSubjectBySubjectIds(this.classInfo.subject_ids);
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    console.log("Class Detail API Error", error);
                });
        },

        getSubjectBySubjectIds($subjectIds) {
          var request = {
            params: {
              campus_id: store.state.user.campus_id,
              subject_ids: $subjectIds,
              class_id: this.$route.params.id
            }
          }
          axios.get("/subject/getSubjectAndTeacherBySubjectIdsAndClass", request)
            .then(response => {
              this.subjectAndTeacherArr = response.data;
              this.isLoading = false;
            })
            .catch(error => {
              if (error.response.status == 401) {
                this.$store.commit('logout');
                window.location.replace('login');
              }
              this.isLoading = false;
              console.log("Subject API Error", error);
            });
        },

        getAcademicYearByGradeId() {
            axios.get("/getAcademicYearByGradeId", {
                params: {
                    grade_id: this.classInfo.grade_id,
                    campus_id: store.state.user.campus_id
                }
            })
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select'
                    }];
                    this.academicYearArr = $defaultSelectOption.concat(res.data.academicYearArr);
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        showList() {
            this.$router.push({
                path: "/class/list"
            });
        },
    },

    mounted() {
        this.isLoading = true;
        this.getGradeName();
        this.getClassDetail();
    },
};
