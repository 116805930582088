export default Object.freeze ({
    NO_DATA_FOUND : 'No Data Found !',
    NO_DATA_EXIST : 'There is no data.',
    IMAGE_TOO_LARGE : 'Image too large!',
    OTHER_LOGIN_FAILED : 'Other Role Login Failed',
    IMAGE_MAX_FILE_SIZE : 4194304,
    IMAGE_MAX_SIZE_ERROR: 'Only maximun 4Mb imges size is allowed.',
    ENABLE_QR : {
        DISABLE : 0,
        ENABLE : 1
    },
    TEACHER_PARENT_CHAT : {
        DISABLE: 0,
        ENABLE : 1
    },
    ROLE : {
        ADMIN : 1,
        STAFF : 2,
        OTHER : 3
    },
    CHAT_REQUEST_STATUS : {
        PENDING : 0,
        APPROVED : 1,
        REJECTED : 2
    }
})
