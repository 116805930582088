import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";
import axios from "axios";
import upLoad from '@/assets/img/screenshoot_upload.png';

export default{
  name: "SettingPage",

  components: {
    MainLayout,
    Loading,
  },

  data() {
    return {
      isLoading: false,
      disabled: false,
      settings: {
        teacher_chat_approval: false,
        send_notice_notifications: false,
        signature: upLoad,
      },
      
    };
  },

  computed: {
    ...mapGetters(["getUser"]),
  },

  methods: {
    getSetting() {
      this.isLoading = true;
      axios
        .get("/setting/" + this.$store.state.user.campus_id)
        .then((response) => {
          this.settings.teacher_chat_approval = response.data.teacher_chat_approval == 1 ? true : false;
          this.settings.send_notice_notifications = response.data.send_notice_notifications == 1 ? true : false;
          this.settings.signature = response.data.signature == null ? upLoad : response.data.signature;
          this.isLoading = false;
        })
        .catch((error) => {
            if (error.response && error.response.status == 401) {
                this.$store.commit('logout');
                window.location.replace('login');
            }
        });
    },
  },

  mounted() {
    this.getSetting()
  }
}
