import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import {
  required,
  maxLength
} from "vuelidate/lib/validators";
import axios from 'axios';
import CONFIG from "@/assets/js/config.js";
import Loading from "vue-loading-overlay";

export default {
  name: "DailyFerryEdit",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    Loading,
  },

  data() {
    return {
      DFData: [],
      selectedPickups: [],
      driverArr: [],
      total_seat: null,
      pickupData: null,
      ferryArr: [],
      routeArr: [],
      submitted: false,
      disabled: false,
      pointError: false,
      isLoading: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
    };
  },

  validations: {
    DFData: {
      driver_id: {
        required
      },
      ferry_id: {
        required
      },
      route_id: {
        required
      },
      date: {
        required,
        maxLength: maxLength(20)
      }
    },
  },

  methods: {
    getDriver() {
      axios.get('driver/getDriverForDailyFerry', {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(res => {
          this.driverArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getFerry() {
      axios.get('ferry/getFerryForDailyFerry', {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(res => {
          this.ferryArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getTotalSeat(ferry_id) {
      axios.get(`ferry/getTotalSeat/${ferry_id}`, {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(res => {
          this.total_seat = res.data[0].total_seats;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getRoute() {
      axios.get('route/getRouteForRoutePoint', {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(res => {
          this.routeArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getPickup(route_id) {
      axios.get(`dailyFerry/getPickup/${route_id}`, {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(res => {
          this.pickupData = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    CheckTotal(index) {
      if (this.total_seat != null) {
        if (this.total_seat < this.selectedPickups.length) {
          alert("This is over weight. Please remove some people from this route");
          document.getElementById(index).checked = false;
          this.selectedPickups.splice(-1, 1);
        }
      }
    },

    getDetail() {
      axios.get(`dailyFerry/detail/${this.$route.params.id}`, { params: { campus_id: this.$store.state.user.campus_id } })
        .then((res) => {
          if (res.data) {
            this.DFData = res.data.data;
            if (this.DFData.route_id != null) {
              this.getPickup(this.DFData.route_id);
            }
            if (this.DFData.ferry_id != null) {
              this.getTotalSeat(this.DFData.ferry_id);
            }
            if (this.DFData.daily_pickups != null) {
              this.selectedPickups = this.DFData.daily_pickups;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    update() {
      this.submitted = true;
      if ((this.pickupData != null && this.selectedPickups.length == 0) || this.selectedPickups.length == 0) {
        this.pointError = true;
      } else {
        this.pointError = false;
      }
      if (this.$v.$invalid || this.pointError == true) {
        return;
      }
      this.disabled = true;
      this.DFData.loginId = this.$store.state.user.id;
      this.DFData.pickup = this.selectedPickups;
      axios.post(`dailyFerry/update/${this.$route.params.id}`, this.DFData)
        .then(res => {
          if (res.data.success == true) {
            this.$notification.success("Daily Ferry Updated Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({ name: "dailyFerryList" });
          } else {
            this.disabled = false;
            this.$notification.error(res.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.disabled = false;
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.isLoading = true;
    this.getRoute();
    this.getDriver();
    this.getFerry();
    this.getDetail();
  }
};
